import useUpgradePlaces from '$/graphql/hooks/upgradePlaces/useUpgradePlaces';
import useCurrentUser from '$/graphql/hooks/user/useCurrentUser';
import useCurrentCommunity from '$/graphql/hooks/user/useUserCommunity';
import useFutureLease from '$/graphql/hooks/user/useUserFutureLease';
import useCurrentIncentives from '$/graphql/hooks/user/useUserIncentives';
import useCurrentLease from '$/graphql/hooks/user/useUserLease';
import useRenewalOffer from '$/graphql/hooks/user/useUserRenewalOffer';
import useUserSummary from '$/graphql/hooks/user/useUserSummary';
import useAppStatus from '$/hooks/useAppStatus';
import { from } from '$/styles/responsive';
import { useMediaQuery } from '@mui/material';

import {
  useCashRedemption,
  useCashRedemptionForm,
  useDeclineOffer,
  useHandlers,
  useHomeStatusContent,
} from './logic';
import HomeStatus from './types';

export default function useConnect() {
  const isDesktop = useMediaQuery(from.tabletLandscape.query);
  const { data: userSummary, loading } = useUserSummary();
  const { data: user } = useCurrentUser();
  const { data: currentIncentives } = useCurrentIncentives();
  const { data: currentCommunity } = useCurrentCommunity();
  const { data: currentLease } = useCurrentLease();
  const { data: futureLease } = useFutureLease();
  const { data: currentRenewalOffer } = useRenewalOffer();

  const { data: upgradeData } = useUpgradePlaces();

  const leaseStatus = currentLease && currentLease.status;

  const appStatus = useAppStatus({
    upgradeStatus: upgradeData?.upgradeStatus,
    currentLease,
    currentRenewalOffer,
  });

  const { homeStatus, renewalExpired } = appStatus;

  const homeStatusContent = useHomeStatusContent({
    homeStatus,
    communityName: currentCommunity?.name,
    futureLease,
    user,
  });

  const { mainText, messageInfo, messageLink } = homeStatusContent;

  const { hiddenFormValues, isModalOpen } = useCashRedemptionForm({
    userSummary,
    currentLease,
    currentCommunity,
    upgradeData,
  });

  // TO-DO: this shouldn't be needed anymore
  const isCashRedeemable = useCashRedemption({
    incentive: currentIncentives,
  });

  const {
    onViewWallet,
    onSearch,
    onSeeUpgradableUnits,
    onUpdateInfo,
    onViewRenewalOffers,
    onViewYourInterest,
  } = useHandlers();

  const { handleOnClick } = useDeclineOffer();

  const renewalOffered = homeStatus === HomeStatus.RenewalOffered;
  const expiredRenewalOffered = renewalExpired && renewalOffered;

  const renewalDeclined = homeStatus === HomeStatus.RenewalDeclined;

  return {
    amount: currentIncentives?.balance ?? 0,
    handle: {
      viewWallet: onViewWallet,
      search: onSearch,
      seeUpgradableUnits: onSeeUpgradableUnits,
      updateInfo: onUpdateInfo,
      viewRenewalOffers: onViewRenewalOffers,
      declineOffer: handleOnClick,
      viewYourInterest: onViewYourInterest,
    },
    hiddenFormValues,
    homeStatus,
    leaseStatus,
    isCashRedeemable,
    isDesktop,
    isModalOpen,
    loading,
    mainText,
    messageInfo,
    messageLink,
    renewalExpired,
    user: userSummary,
    currentIncentives,
    currentLease,
    currentRenewalOffer,
    statusOptions: {
      renewalOffered,
      expiredRenewalOffered,
      renewalDeclined,
    },
  };
}
